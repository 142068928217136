<template>
  <div class="notFound">
    <div class="container">
      <div class="row mt-3">
        <div class="col-sm-12 text-center">
          <h1 style="font-weight: bold; color: #699FB8; font-size: 3em;">
            404 Not Found !
          </h1>
          <p style="font-weight: bold; color: #7C7C7C; font-size: 1.5em; margin-top: 30px;">
            Cette page n'existe pas ou n'existe plus. <br>
            Nous vous prions de nous excuser pour la gène occasionnée.
          </p>

          <div class="row" style="margin-top: 30px;">
            <div class="col-12 text-center">
              <b-button
                pill block variant="outline-info" class="mt-2 btn-lg"
                @click="$router.push('/')">
                Retour à l'accueil
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notFound',
}
</script>
